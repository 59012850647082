<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        width="70%"
        :height="350"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;height: inherit;">
                <div class="px-5 pt-8">
                    <h1 class="text-left text-secondary fs-14 fw-600" v-if="mode == 'add'">Add Bank Details</h1>
                    <h1 class="text-left text-secondary fs-14 fw-600" v-else-if="mode == 'edit'">Edit Bank Details</h1>
                    <h1 class="text-left text-secondary fs-14 fw-600" v-else>View Bank Details</h1>
                </div>
                <div class="v-modal-body p-0">
                    <div class="v-modal-layout">
                        <!-- <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 text-white">Bank Details 1</span>
                            <span class="pointer" @click="expandTabs('bank_info')">
                                <i class="text-white" :class="{'icon-chevron-double-down':bank_info_expand, 'icon-chevron-double-up':!bank_info_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div> -->
                        <div class="px-5" :class="{'mt-8':mode == 'view'}">
                            <div class="row" v-if="ajax_call_in_progress">
                                <div class="col-lg-12 py-5 px-3 mt-3">
                                    <div class="flex-container">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">Account Name</span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="100" v-validate="'alpha_spaces'" :readonly="mode == 'view'" v-model="account_name" class="input-field-bank-info"
                                                        placeholder="Enter Account Name" name="account_name" />
                                                </div>
                                            </div>
                                            <div v-if="mode != 'view'" class="text-right" >
                                                <span class="input-field-length">{{ account_name.length }}/100</span>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('account_name')">
                                                    {{errors.first('account_name')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">Account Number<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="100" :readonly="mode == 'view'" v-validate="{required: true, numeric: true, regex: /^\d{9,18}$/}" v-model="account_number" class="input-field-bank-info"
                                                        placeholder="Enter Account Number" name="account_number" />
                                                </div>
                                            </div>
                                            <span v-if="mode != 'view'" class="invalid-feedback-form text-danger" v-show="errors.has('account_number')">
                                                {{errors.first('account_number')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">Bank Name</span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="100" :readonly="mode == 'view'" v-validate="'alpha_spaces'" v-model="bank_name" class="input-field-bank-info"
                                                        placeholder="Enter Bank Name" name="bank_name" />
                                                </div>
                                            </div>
                                            <div v-if="mode != 'view'" class="text-right" >
                                                <span class="input-field-length">{{ bank_name.length }}/100</span>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('bank_name')">
                                                {{errors.first('bank_name')}}
                                            </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">Branch Name</span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="30" :readonly="mode == 'view'" v-validate="'alpha_spaces'" v-model="bank_branch_name" class="input-field-bank-info"
                                                        placeholder="Enter Branch Name" name="bank_branch_name" />
                                                </div>
                                            </div>
                                            <div v-if="mode != 'view'" class="text-right" > 
                                                <span class="input-field-length">{{ bank_branch_name.length }}/30</span>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('bank_branch_name')">
                                                {{errors.first('bank_branch_name')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">IFSC Code<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="10" :readonly="mode == 'view'" v-validate="'required|alpha_num'" v-model="ifsc_code" class="input-field-bank-info"
                                                        placeholder="Enter IFSC Code" name="ifsc_code" />
                                                </div>
                                            </div>
                                            <div v-if="mode != 'view'" class="text-right" >
                                                <span class="input-field-length">{{ ifsc_code.length }}/10</span>
                                            </div>
                                            <span v-if="mode != 'view'" class="invalid-feedback-form text-danger" v-show="errors.has('ifsc_code')">
                                             IFSC Code is required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                                        <div class="w-100">
                                            <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <span class="input-label pb-0 pl-0" style="min-width: 130px;">Swift Code</span>
                                                <div class="d-flex align-items-center w-100">
                                                    <input type="text" maxlength="30" :readonly="mode == 'view'" class="input-field-bank-info" v-validate="'alpha_num'" v-model="swift_code" placeholder="Enter Swift Code" name="swift_code" />
                                                </div>
                                            </div>
                                            <div v-if="mode != 'view'" class="text-right" >
                                                <span class="input-field-length">{{ swift_code.length }}/30</span>
                                            </div>
                                            <span v-if="mode != 'view'" class="invalid-feedback-form text-danger" v-show="errors.has('swift_code')">
                                                {{errors.first('swift_code')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions">
                    <div>
                        <h1 v-if="warning_msg" class="text-danger fs-14">{{ warning_msg }}</h1>
                        <div class="d-flex justify-content-center align-items-center">
                            <button class="btn btn-outline-secondary" @click="hideCreateBank" :disabled="save_create_btn_hide || !bank_info_expand" style="width: 120px;border-radius: 5px;">Cancel</button>
                            <button class="btn btn-new-success ml-5" v-if="mode == 'edit' || mode == 'add'" :disabled="save_create_btn_hide || !bank_info_expand || fields_not_changed" @click="addBank" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="save_create_btn_hide || !bank_info_expand" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import companies from '../../mixins/companies';
import { SweetModal } from 'sweet-modal-vue';
import {
    HalfCircleSpinner
} from "epic-spinners";
export default {
    data() {
        return {
            save_create_btn_hide: false,
            bank_info_expand: true,
            warning_msg: '',
            success_msg: '',
            bank_name: '',
            bank_branch_name: '',
            account_name: '',
            account_number: '',
            ifsc_code: '',
            swift_code: '',
            ajax_call_in_progress: false,
            stringified_response: "",
        }
    },
    props: ["modal_name", 'mode', 'bank_id'],
    components: {
        SweetModal,
        HalfCircleSpinner,
        HollowDotsSpinner
    },
    mixins: [companies],
    methods: {
        hideCreateBank() {
            setTimeout(() => {
                this.$modal.hide(this.modal_name);
            }, 500);
            this.$emit("hideCreateBank");
        },
        saveCreateBank() {
            setTimeout(() => {
                this.$modal.hide(this.modal_name);
            }, 500);
            this.$emit("hideCreateBanksucc");

        },
        expandTabs(type) {
            if(type == 'bank_info') {
                this.bank_info_expand = !this.bank_info_expand;
            } 
        },
        async addNewBankDetails() {
            try {
                this.save_create_btn_hide = true;
                let response;
                if(this.mode == 'add') {
                    console.log("hi123byew")
                    response = await this.addBankDetails(this.create_data);
                } else {
                    console.log("hi123")
                    response = await this.updateBankDetailsById(this.create_data, this.bank_id);
                }
                if(response.status_id == 1) {
                    this.success_msg = response.message;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.saveCreateBank();
                    }, 3000);
                }
            }
            catch(err) {
                this.save_create_btn_hide = false;
                this.warning_msg = err;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    // this.hideCreateBank();
                }, 3000);
            }
            this.save_create_btn_hide = false;
        },
        addBank() {
            this.$validator.validateAll().then(result => {
                if (result == true) {
                    this.addNewBankDetails();
                }
            })
        },
        async getBankDataById(id) {
            try {
                this.ajax_call_in_progress = true;
                let response = await this.getBankDetailsById(id);
                console.log("hi123")
                if(response.status_id == 1) {
                    localStorage.setItem('bank_details_info', JSON.stringify(response.response));
                    let data = response.response;
                    this.bank_name = data.bank_name;
                    this.bank_branch_name = data.bank_branch_name;
                    this.account_name = data.account_name;
                    this.account_number = data.account_number;
                    this.ifsc_code = data.ifsc_code;
                    this.swift_code = data.swift_code;
                }
                this.ajax_call_in_progress = false;
                this.stringified_response = JSON.stringify(this.create_data)
            }
            catch(err) {
                this.ajax_call_in_progress = false;
            }
        }
    },
    mounted() {
        if(this.mode == 'edit' || this.mode == 'view') {
            this.getBankDataById(this.bank_id);
        }
    },
    watch: {
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        // account() {
        //     return this.$store.getters.account;
        // },
        // selectedCompany() {
        //     return this.$store.getters.selectedCompany;
        // }, 
        fields_not_changed(){
            return this.stringified_response == JSON.stringify(this.create_data)
        },
        create_data() {
            return {
                bank_name: this.bank_name,
                bank_branch_name: this.bank_branch_name,
                account_number: String(this.account_number),
                account_name: this.account_name,
                ifsc_code: this.ifsc_code,
                swift_code: this.swift_code,
                company_id: this.$route.params.company_id
            }
        }
    },
    created() {
        const dict = {
            custom: {
                bank_name: {
                    alpha_spaces:() => "Bank Name should be alphabetical",
                },
                bank_branch_name: {
                    alpha_spaces:() => "Bank Branch Name should be alphabetical",
                },
                account_name: {
                    alpha_spaces:() => "Account Name should be alphabetical",
                },
                account_number:{
                    required:() => "Account number is required",
                    regex:() => "Account number must be atleast 9 digits long and can't be greater than 18 digits.",
                    numeric:() => "Account Number should be numeric",
                },
                ifsc_code:{
                    alpha_num:() => "IFSC code must be alphanumeric.",
                },
                swift_code:{
                    alpha_num:() => "SWIFT code must be alphanumeric.",
                },
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
	.v-modal-header {
		display: -webkit-box;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-webkit-justify-content: center;
		-ms-flex-pack: justify;
		justify-content: center;
        color: #00448b !important;
		padding: 15px;
		border-bottom: 0px solid #E9ECEF;
		-webkit-border-top-left-radius: 15px;
		border-top-left-radius: 15px;
		-webkit-border-top-right-radius: 15px;
		border-top-right-radius: 15px;
		background-color: #f5f6f9 !important;
	}

	.v-modal-content {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 0px solid rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 2px;
		border-radius: 2px;
		-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		outline: 0;
	}

	.v-modal-dialog-title.v-modal-title {
		letter-spacing: .005em;
	}

	.v-modal-dialog-title {
		margin-bottom: 0px !important;
		font-size: 15px !important;
		font-weight: 600 !important;
		font-style: normal;
		display: block;
		font-stretch: normal;
		line-height: normal;
		color: #2b6ad0 !important;
		text-transform: uppercase !important;
		margin: 0 auto !important;
		padding: 4px !important;
	}

	.v-modal-body {
		overflow: auto;
		width: 100%;
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 15px;
	}

	.new-modal-default .v-modal-header {
		background-color: #005dae !important;
		height: 50px;
        color: #fff;
        display: flex;
	}

    .v-modal-dialog-title {
        color: #fff !important;
    }

	/* .v-modal-layout {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		} */
	.v-modal-dialog-actions {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 15px;
		border-top: 0px solid transparent;
	}
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>